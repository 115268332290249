#top_menu {
  width: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, .667);
  flex-direction: row;
  justify-content: end;
  padding: 0;
  display: flex;
  position: fixed;
}

.menu_logo, .menu_link {
  letter-spacing: .8px;
  color: #fff;
  text-shadow: 0 3px 6px rgba(0, 0, 0, .68);
  z-index: 9999;
  margin: 0;
  padding: 20px 3vw;
  font: 600 23px / 29px Sen;
  text-decoration: none;
}

.menu_logo {
  z-index: 9999;
  padding: 12px 20px 15px;
  display: block;
  position: absolute;
  left: 0;
}

.menu_logo img {
  max-width: 200px;
}

@media only screen and (max-width: 1000px) {
  .menu_logo img {
    max-height: 35px;
  }
}

.menu_link:hover {
  color: #cdcdcd;
}

@media only screen and (max-width: 1000px) {
  #top_menu {
    flex-direction: column;
    padding-top: 70px;
  }

  .menu_logo {
    padding: 20px 20px 8px;
    display: block;
    top: 0;
    left: 0;
  }

  .menu_link {
    float: left;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, .333);
    border-bottom: 1px solid rgba(68, 68, 68, .333);
    padding: 15px 6vw;
    display: none;
  }

  #top_menu.menu_active .menu_link {
    display: block;
  }

  .menu_link:hover {
    background: rgba(0, 0, 0, .2);
  }

  .menu_logo {
    width: 100%;
    background: rgba(0, 0, 0, .333);
    display: block;
  }
}

#header {
  height: 120vh;
  background: #fff;
  display: grid;
}

@media only screen and (max-width: 800px) {
  #header {
    height: 77vh;
  }
}

.header_layer {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: 1 / 1;
}

#header_sky {
  background-image: url("sky.2f2aee96.webp");
}

#header_logo {
  z-index: 9992;
  background-image: url("header_logo.15282ba6.png");
}

#header_back_mountains {
  z-index: 9993;
  background-image: url("mountain_back.79a36dd7.webp");
}

#header_back_castle {
  background-image: url("castle_back.55f1b2e6.webp");
}

#header_monsters_far {
  z-index: 9995;
  background-image: url("monsters_far.d9829070.webp");
}

#header_rain_far {
  opacity: .03;
  z-index: 9993;
  background-image: url("rain.61103543.gif");
  background-repeat: repeat;
  background-size: 50%;
}

#header_mountains {
  z-index: 9994;
  background-image: url("mountain_middle.bf326e0b.webp");
}

#header_castle {
  z-index: 9995;
  background-image: url("castle_back.55f1b2e6.webp");
}

#header_cave {
  z-index: 9996;
  background-image: url("cave.11732d96.webp");
}

#header_monsters {
  z-index: 9996;
  background-image: url("monsters.63b233bf.webp");
}

#header_heroes {
  z-index: 9997;
  background-image: url("heroes.8b4f5318.webp");
}

#header_rain {
  opacity: .05;
  z-index: 9998;
  background-image: url("rain.61103543.gif");
}

#header_foreground {
  z-index: 9999;
  background-image: url("foreground.246f3ad5.webp");
}

#header_fog_a {
  z-index: 9999;
  background-image: url("fog_a.e6b5417c.png");
}

#header_fog_b {
  z-index: 9997;
  background-image: url("fog_b.e4cef00c.png");
}

#header_fog_c {
  z-index: 9994;
  background-image: url("fog_c.85ff52ab.png");
}

#header_fog_d {
  z-index: 9993;
  background-image: url("fog_d.74da17dc.png");
}

@media only screen and (max-width: 800px) {
  #header_sky {
    background-image: url("sky.d56d1abe.jpg");
  }

  #header_logo {
    z-index: 9996;
    background-image: url("mobile_header_logo.955a3e28.png");
  }

  #header_back_mountains {
    background-image: url("mountain_back.9f74f1b2.webp");
  }

  #header_back_castle {
    background-image: url("castle_back.bf95a14c.webp");
  }

  #header_monsters_far {
    display: none;
  }

  #header_rain_far {
    opacity: .05;
    z-index: 9993;
    background-image: url("rain.61103543.gif");
    background-repeat: repeat;
    background-size: 50%;
  }

  #header_mountains {
    z-index: 9994;
    background-image: url("mountain_middle.df43ff98.webp");
  }

  #header_castle {
    z-index: 9995;
    background-image: url("castle_back.bf95a14c.webp");
  }

  #header_cave {
    z-index: 9996;
    background-image: url("cave.91d1e445.webp");
  }

  #header_castle_front {
    z-index: 9995;
    background-image: url("castle_front.951d2e45.webp");
  }

  #header_monsters {
    z-index: 9996;
    background-image: url("monsters.5973c99b.webp");
  }

  #header_heroes {
    z-index: 9997;
    background-image: url("heroes.d8622527.webp");
  }

  #header_rain {
    opacity: .05;
    z-index: 9998;
    background-image: url("rain.61103543.gif");
  }

  #header_foreground {
    z-index: 9999;
    background-image: url("foreground.96aab2a7.webp");
  }

  #header_fog_a {
    z-index: 9999;
    background-image: url("fog_a.d20f26ee.webp");
  }

  #header_fog_b {
    z-index: 9997;
    background-image: url("fog_b.a511e4ba.webp");
  }

  #header_fog_c {
    z-index: 9994;
    background-image: url("fog_c.be86f8de.webp");
  }

  #header_fog_d {
    z-index: 9993;
    background-image: url("fog_d.2cdb90ab.webp");
  }
}

.section_separator {
  height: 150px;
  width: 100%;
  background: url("section_separator.ff765cd6.png") center no-repeat;
  margin: 100px 0 150px;
}

@media only screen and (max-width: 1000px) {
  .section_separator {
    margin: 20vw 0;
  }
}

.section_separator_dark_light {
  height: 150px;
  width: 100%;
  z-index: 9999;
  background: url("section_separator_dark_light.f39fed34.svg") center no-repeat;
  margin-top: -73px;
  margin-bottom: -74px;
  position: absolute;
  left: 0;
}

.section_separator_light_dark {
  height: 150px;
  width: 100%;
  background: url("section_separator_light_dark.8a6bb7b4.svg") center no-repeat;
  margin-top: -75px;
}

@media only screen and (max-width: 1000px) {
  .section_separator_dark_light, .section_separator_light_dark {
    background-size: 110%;
  }
}

#houses_background {
  background: url("buildings.7dc4df1f.webp") top / auto 120vh no-repeat fixed;
}

#mountains_background {
  background: url("mountains.eeac6268.webp") 50% / auto 120vh no-repeat fixed;
}

.spotlight_background {
  background: radial-gradient(closest-side, rgba(42, 22, 83, .9) 0%, #140d23 100%) no-repeat padding-box padding-box;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section_title {
  width: 100%;
  background: linear-gradient(0deg, rgba(18, 9, 32, 0) 50%, #060307 100%) 0 0 / contain;
  flex-direction: column;
  align-items: center;
  padding: 0 50px 20px;
  display: flex;
}

.section_title_frame_top {
  width: 70%;
  background: url("game_title_top.ba886ee7.svg") top / 100% repeat-y;
  padding: 0;
}

@media only screen and (max-width: 1000px) {
  .section_title_frame_top {
    width: 90%;
  }
}

#blendint_top {
  width: 100%;
  height: 30vh;
  background: linear-gradient(0deg, rgba(18, 9, 32, 0) 0%, #09040b 100%) 0 0 / contain;
  margin-bottom: -30vh;
}

#game_title {
  text-align: center;
  letter-spacing: .8px;
  color: #fff;
  margin: 0;
  padding: 150px 50px 25px;
  font: bold calc(26px + 1.6vw) / calc(32px + 1.8vw) Cinzel;
}

#game_subtitle {
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: .24px;
  color: #fff;
  margin: 0 50px 30px;
  font: 300 calc(16px + .3vw) / calc(24px + .55vw) Sen;
}

@media only screen and (max-width: 800px) {
  #game_title {
    text-align: left;
    padding: 25px;
  }

  #game_subtitle {
    text-align: left;
    margin: 0 25px 30px;
    font: 300 calc(16px + .3vw) / calc(30px + .55vw) Sen;
  }
}

#stats {
  color: #fff;
  width: 60%;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 75px auto 0;
  display: flex;
}

@media only screen and (max-width: 800px) {
  #stats {
    width: 100%;
    padding-bottom: 0;
  }
}

.stat {
  flex-direction: column;
  align-items: center;
  margin-bottom: -50px;
  display: flex;
}

.stat_big {
  font-size: calc(12px + 1.2vw);
  font-weight: 800;
  line-height: calc(12px + 1.2vw);
}

.stat_small {
  font-size: calc(12px + .2vw);
  line-height: calc(16px + .4vw);
}

@media only screen and (max-width: 800px) {
  .stat {
    margin-bottom: 0;
  }

  .stat_big {
    font-size: calc(20px + 1.2vw);
    font-weight: 800;
    line-height: calc(24px + 1.2vw);
  }

  .stat_small {
    font-size: calc(18px + .1vw);
    line-height: calc(24px + .3vw);
  }
}

#section_title_frame_bottom {
  width: 70%;
}

#section_title_frame_bottom img {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  #section_title_frame_bottom {
    width: 90%;
  }
}

.description {
  width: 80%;
  flex-direction: column;
  align-items: center;
  padding: 70px 50px 20px;
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .description {
    width: 90%;
    padding: 20px 50px;
  }
}

.illustration {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media only screen and (max-width: 1000px) {
  .illustration {
    grid-template-columns: auto;
  }
}

.crest_background {
  width: 100%;
  max-width: 60%;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1000px) {
  .crest_background {
    max-width: 40%;
  }
}

.illustration_text {
  text-align: left;
  grid-area: 1 / 1 / 2;
}

@media only screen and (max-width: 1800px) {
  .illustration_text {
    flex-direction: column;
    grid-area: 1 / 1 / 3;
    justify-content: center;
    display: flex;
  }
}

.illustration_text.reverse_video_order {
  grid-column: 2;
}

@media only screen and (max-width: 1000px) {
  .illustration_text {
    grid-area: 2 / 1 / 3;
    padding-bottom: 0;
  }

  .illustration_text.reverse_video_order {
    grid-column: 1;
    margin-top: 0;
  }
}

.illustration_title {
  letter-spacing: .6px;
  color: #fff;
  margin: 0;
  padding: 0 20px 20px;
  font: bold calc(26px + 1vw) / calc(32px + 1vw) Cinzel;
}

.illustration_subtitle {
  letter-spacing: .24px;
  color: #fff;
  margin: 0;
  padding: 0 0 20px 20px;
  font: 300 calc(16px + .3vw) / calc(24px + .45vw) Sen;
}

@media only screen and (max-width: 1000px) {
  .illustration_title {
    padding: 0 25px 20px;
  }

  .illustration_subtitle {
    padding: 0 25px 20px;
    font: 300 calc(16px + .3vw) / calc(30px + .45vw) Sen;
  }
}

.illustration_image {
  grid-area: 1 / 2 / 3;
}

.illustration_image.reverse_video_order {
  grid-column: 1;
}

@media only screen and (max-width: 1000px) {
  .illustration_image {
    grid-area: 1 / 1 / 2;
  }
}

.illustration_video_frame {
  background: url("video_frame.f169597d.svg") 0 0 / contain no-repeat;
  margin: 0 60px;
  padding: 25px;
}

@media only screen and (max-width: 1800px) {
  .illustration_video_frame {
    max-width: 100%;
    margin: 0 auto;
    padding: 15px;
  }
}

.illustration video {
  width: 100%;
  margin: 0 auto;
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .illustration video {
    max-width: 100%;
    margin: 0 0 30px;
  }
}

.spots {
  width: 100%;
  flex-direction: row;
  grid-area: 2 / 1 / 3;
  margin: 0 auto 20px;
  display: flex;
}

.spots.reverse_video_order {
  grid-column: 2;
}

@media only screen and (max-width: 1800px) {
  .spots, .spots.reverse_video_order {
    grid-area: 3 / 1 / 3 / 3;
  }
}

@media only screen and (max-width: 1000px) {
  .spots {
    flex-wrap: wrap;
    grid-area: 3 / 1 / 4;
    justify-content: center;
    align-items: center;
  }

  .spots.reverse_video_order {
    grid-area: 3 / 1 / 4;
  }
}

.spot {
  max-width: 33%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

@media only screen and (max-width: 1800px) {
  .spot {
    width: 45%;
    flex-grow: 0;
    flex-shrink: 1;
    align-items: center;
    padding: 20px 30px 0;
  }
}

@media only screen and (max-width: 1000px) {
  .spot {
    width: 45%;
    flex-grow: 0;
    align-items: center;
    padding: 20px 5px 0;
  }
}

.spot img {
  width: 100%;
  background: url("icons_frame.78c16e70.svg") 50% / 110%;
}

@media only screen and (max-width: 1000px) {
  .spot:last-child {
    margin-top: -15%;
  }

  .spot img {
    order: 1;
  }

  .spot:last-child img {
    order: 0;
  }
}

.click_dot {
  width: 10px;
  height: 10px;
  background: #cdcdcd;
  border: 4px solid rgba(255, 255, 255, .333);
  margin: -30px 0 20px;
  display: none;
  transform: rotate(45deg);
}

.spot:hover .click_dot {
  background: #6d6d6d;
}

.spot_label {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  font: calc(16px + .2vw) / calc(18px + .3vw) Sen;
}

.scene_headline {
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: .24px;
  color: #fff;
  margin: 0 0 50px;
  font: calc(16px + .8vw) / calc(22px + 1vw) Sen;
}

.reveal {
  opacity: 0;
  transition: all 2s;
  position: relative;
  transform: translateY(150px);
}

.reveal.active {
  opacity: 1;
  transform: translateY(0);
}

.reveal_left {
  opacity: 0;
  transition: all 2s;
  position: relative;
  transform: translateX(-150px);
}

.reveal_left.active {
  opacity: 1;
  transform: translateX(0);
}

.reveal_right {
  opacity: 0;
  transition: all 2s;
  position: relative;
  transform: translateX(150px);
}

.reveal_right.active {
  opacity: 1;
  transform: translateX(0);
}

#defenders_scene {
  width: 100%;
  background: url("standup_background.568b6260.webp") 0 70% / 100% no-repeat;
  flex-flow: wrap;
  margin-bottom: 100px;
  display: flex;
}

.Man1 {
  background: url("Man1.bcf9e574.webp") no-repeat;
  margin-top: 25px;
}

.Woman1 {
  background: url("Woman1.0ad135dc.webp") no-repeat;
}

.Man2 {
  background: url("Man2.d5b40048.webp") no-repeat;
}

.Woman2 {
  background: url("Woman2.3085cc3f.webp") no-repeat;
  margin-top: 25px;
}

.defender {
  width: 25%;
  height: 25vw;
  background-position: 0 100%;
  background-size: 1800%;
}

@media only screen and (max-width: 1000px) {
  #defenders_scene {
    background: url("standup_background-mobile.bd8b8fc2.png") bottom / 80% no-repeat;
    margin-top: -70px;
    margin-bottom: 20px;
    padding-bottom: 0;
  }

  .defender {
    width: 50%;
    height: 50vw;
  }

  .Man1, .Woman2 {
    order: 3;
    margin-top: 0;
  }

  .Woman1, .Man2 {
    order: 2;
    margin-top: 20px;
  }

  .Man2 {
    margin-top: 35px;
  }
}

#monsters_scene {
  width: 100%;
  background: url("monsters_background.e85d0576.png") 0 20% / 100% no-repeat;
  flex-flow: wrap;
  margin-top: 150px;
  margin-bottom: 150px;
  display: flex;
}

.Ghoul {
  width: 19.2708%;
  height: 13.883vw;
  background: url("Ghoul.b93f1982.webp") 0 0 / 1600% no-repeat;
}

.Drake {
  width: 15.625%;
  height: 13.4043vw;
  background: url("Drake.2fcf6d5f.webp") 0 0 / 1600% no-repeat;
}

.Elemental {
  width: 27.0833%;
  height: 19.9149vw;
  background: url("Elemental.4108ffdd.webp") 0 0 / 1600% no-repeat;
}

.SmallGhoul {
  width: 16.6667%;
  height: 13.883vw;
  background: url("SmallGhoul.70b240e2.webp") 0 0 / 1600% no-repeat;
}

.Turtle {
  width: 20.8333%;
  height: 13.883vw;
  background: url("Turtle.6fd054d5.webp") 0 0 / 1600% no-repeat;
}

.monster {
  background-position: 0 100%;
}

@media only screen and (max-width: 800px) {
  #monsters_scene {
    background: url("monsters_background-mobile.ad23bfc0.webp") center / contain no-repeat;
    margin-top: -20px;
    margin-bottom: 20px;
    padding: 0;
  }

  .monster {
    width: 50%;
    height: 50vw;
  }

  .Turtle, .Elemental {
    order: 1;
    margin-top: 0;
  }

  .Ghoul, .SmallGhoul {
    order: 2;
  }

  .Drake {
    order: 3;
    margin-top: -90%;
    margin-left: 30%;
    display: none;
  }
}

#artstyle {
  background: url("buildings.7dc4df1f.webp") top / auto 120vh no-repeat fixed;
}

#spotlight_blue_background {
  background: radial-gradient(closest-side, rgba(100, 75, 158, .9) 0%, #2968c6 100%) no-repeat padding-box padding-box;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#artstyle_container {
  width: 70%;
}

@media only screen and (max-width: 800px) {
  #artstyle_container {
    width: 90%;
  }
}

#artstyle_container .section_separator {
  margin: -60px auto 0;
  padding: 0;
}

#artstyle_title_holder {
  padding-bottom: 0;
}

#artstyle_title_holder_frame_top {
  background: url("artstyle_title_top.1e72a833.svg") 0 0 / 100% repeat-y;
}

#artstyle_title_holder_frame_bottom img {
  width: 100%;
}

#artstyle_title_holder h3 {
  color: #fff;
  text-align: center;
  letter-spacing: .6px;
  background: linear-gradient(0deg, rgba(18, 9, 32, 0) 0%, #2b68c8 100%);
  margin: 0;
  padding: 180px 50px 50px;
  font: 600 60px / 72px Cinzel;
}

#artstyle_title_holder p {
  color: #fff;
  text-align: center;
  letter-spacing: .24px;
  background-size: 100%;
  margin: 0;
  padding: 0 50px;
  font: 300 20px / 32px Sen;
}

@media only screen and (max-width: 1000px) {
  #artstyle_title_holder {
    width: 100%;
    padding-bottom: 0;
  }

  #artstyle_title_holder h3 {
    text-align: left;
    padding: 60px 25px 20px;
    font: 600 40px / 52px Cinzel;
  }

  #artstyle_title_holder p {
    text-align: left;
    padding: 5px 25px;
    font: 300 calc(16px + .3vw) / calc(30px + .55vw) Sen;
  }
}

.artstyle_star {
  height: 20vh;
  width: 100%;
  z-index: 9999;
  background: url("star.e3679e60.svg") center / contain no-repeat;
  margin-bottom: -30px;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .artstyle_star {
    height: 20vh;
    width: 100%;
    background: url("star_mobile.e553dcd8.svg") center / 160% no-repeat;
    margin-bottom: -40px;
  }
}

#artstyle_fields_holder {
  background: url("line.62ebb924.svg") top / 23px repeat-y;
  flex-flow: wrap;
  margin-bottom: -10px;
  padding-top: 50px;
  padding-bottom: 150px;
  display: flex;
}

@media only screen and (max-width: 800px) {
  #artstyle_fields_holder {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.artstyle_field {
  width: 43%;
  flex-direction: column;
  margin: 0 7% 50px 0;
  display: flex;
}

.artstyle_field_right {
  margin: 0 0 0 7%;
}

.artstyle_field_top {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.artstyle_field_top img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.artstyle_field_middle {
  color: #fff;
  width: 100%;
  background: url("artstyle_field_back.168a6f5b.svg") 0 0 / 100% repeat-y;
  margin: 0;
  padding: 0;
}

.artstyle_field_middle h3 {
  background: url("artstyle_field_title_underline.e5056d37.svg") bottom / contain no-repeat;
  margin: 20px 40px;
  padding-bottom: 20px;
  font: 600 30px / 42px Cinzel;
}

.artstyle_field_middle p, li {
  margin: 20px 50px;
  font: 200 18px / 22px Sen;
}

.artstyle_field_middle ul {
  padding: 0 10px;
}

.artstyle_field_middle li {
  background: url("list_star.2921f11d.svg") 0 0 / 22px no-repeat;
  padding-left: 25px;
  list-style: none;
}

.artstyle_field_bottom img {
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .artstyle_field {
    width: 100%;
    margin: 25px 0;
  }
}

@media only screen and (max-width: 800px) {
  .artstyle_field_middle p, li {
    font: 300 calc(16px + .3vw) / calc(30px + .55vw) Sen;
  }
}

.artstyle_illustration {
  width: 50%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.artstyle_illustration img {
  width: 70%;
  margin: 0 auto 80px;
  display: block;
}

@media only screen and (max-width: 1000px) {
  .artstyle_illustration {
    width: 100%;
    margin: 25px 0;
  }

  .artstyle_illustration img {
    width: 100%;
    margin-bottom: 0;
  }

  .artstyle_illustration.move_to_last {
    order: 4;
  }
}

#artstyling_illustration_left {
  width: 20%;
  height: 100%;
  opacity: .7;
  background: url("prev_arrow.4216783d.svg") center no-repeat;
  position: absolute;
  left: 0;
}

#artstyling_illustration_left:hover {
  opacity: 1;
}

#artstyling_illustration_right {
  width: 20%;
  height: 100%;
  opacity: .8;
  background: url("next_arrow.b5cc6cb4.svg") center no-repeat;
  position: absolute;
  right: 0;
}

#artstyling_illustration_right:hover {
  opacity: 1;
}

#artstyling_illustration {
  width: 80%;
  height: 100%;
  min-height: 500px;
  background: url("golem_artstylings.f308293e.webp") 0 / 400% no-repeat;
  margin: 0 10%;
  transition: background-position .5s;
}

@media only screen and (max-width: 800px) {
  #artstyling_illustration {
    min-height: 300px;
  }

  #artstyling_illustration_left, #artstyling_illustration_right {
    background-size: 60%;
  }
}

.artstyle_illustration .monster {
  width: 85%;
  height: 100%;
  margin: 0 auto;
}

#artstyle_graph_holder {
  width: 100%;
  margin: 0 0 200px;
  display: none;
  overflow: auto;
}

#artstyle_graph_holder img {
  width: 100%;
  margin: 3px auto 65px;
  display: block;
}

#roadmap {
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 120vh;
  background-attachment: fixed;
}

#spotlight_dark_background {
  width: 100%;
  background: radial-gradient(closest-side, rgba(30, 59, 96, .9) 0%, #120920 100%) no-repeat padding-box padding-box;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.roadmap_home_background {
  width: 100%;
  background: url("house.1cdb45d6.png") 100% 100% no-repeat;
  flex-direction: column;
  align-items: center;
  display: flex;
}

#roadmap_container {
  width: 70%;
}

@media only screen and (max-width: 800px) {
  .roadmap_home_background {
    background: url("house_mobile.abd62d76.png") 100% 100% no-repeat;
  }

  #roadmap_container {
    width: 85%;
  }
}

#roadmap_container .section_separator {
  margin: -60px auto 0;
  padding: 0;
}

#radmap_fields_holder {
  background: url("line.62ebb924.svg") 15% / 25px repeat-y;
  flex-flow: wrap;
  padding-bottom: 100px;
  display: flex;
}

@media only screen and (max-width: 800px) {
  #radmap_fields_holder {
    background-position: 16%;
  }
}

.roadmap_star {
  height: 15vh;
  width: 100%;
  color: #fff;
  background: url("roadmap_star.e8080bb8.svg") calc(15% - 45px) / 150px no-repeat;
  margin-bottom: -25px;
}

.roadmap_point_holder {
  width: 100%;
  color: #fff;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  display: flex;
}

.roadmap_point_holder p {
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 40px;
  font-weight: 600;
}

.roadmap_headline {
  color: #fff;
  text-align: center;
  margin-top: 100px;
  margin-bottom: -90px;
  font: 600 60px / 52px Cinzel;
}

@media only screen and (max-width: 800px) {
  .roadmap_headline {
    margin-top: 0;
    margin-bottom: 50px;
  }
}

.roadmap_point_left {
  color: #fff;
  width: 15%;
  text-align: center;
  padding: 25px 0;
  font-family: Cinzel;
  font-size: 30px;
}

.roadmap_point_right {
  color: #fff;
  width: 85%;
  text-align: left;
  background: url("roadmap_line_star.8b31781d.svg") -4px / 50px no-repeat;
  padding: 25px 0 25px 80px;
  font-size: 22px;
}

@media only screen and (max-width: 800px) {
  .roadmap_point_right {
    padding-left: 50px;
    font: 300 calc(16px + .3vw) / calc(30px + .55vw) Sen;
  }
}

.roadmap_illustration {
  position: relative;
  bottom: 0;
  right: 0;
}

#team {
  background: url("buildings.7dc4df1f.webp") top / auto 120vh no-repeat fixed;
}

#team_container {
  width: 70%;
}

#team_container .section_separator {
  margin: -60px auto 0;
  padding: 0;
}

#team_fields_holder {
  flex-flow: wrap;
  justify-content: center;
  padding-bottom: 150px;
  display: flex;
}

#team_fields_holder h3 {
  width: 100%;
  color: #fff;
  text-align: center;
  margin: 150px 0 100px;
  font-family: Cinzel;
  font-size: 60px;
  font-weight: 600;
}

.teammate_holder {
  width: 25%;
  min-width: 300px;
  background-size: contain;
  margin-bottom: 50px;
  font-family: Sen;
}

.teammate_picture {
  padding: 20px 20px 0;
}

.teammate_picture img {
  width: 100%;
  background-size: 100% 100%;
  margin: 10px auto;
  display: block;
}

.teammate_text {
  color: #fff;
  padding: 0 20px;
}

.teammate_name {
  text-align: center;
  margin: 5px;
  font-family: Cinzel;
  font-size: 25px;
}

.teammate_title {
  text-align: center;
  margin: 5px;
  font-family: Sen;
  font-size: 16px;
}

@media only screen and (max-width: 1000px) {
  #team_container {
    width: 100%;
    padding: 0 25px;
  }

  #team_fields_holder {
    padding-bottom: 20px;
  }

  #team_fields_holder h3 {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .teammate_text {
    color: #fff;
    padding: 0 5px;
  }

  .teammate_holder {
    min-width: 190px;
    margin-bottom: 0;
  }
}

#footer {
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 120vh;
  background-attachment: fixed;
}

#footer_container {
  width: 100%;
  color: #fff;
  text-align: center;
  background: url("footer_bg.91a823f3.png") bottom / cover no-repeat;
}

@media only screen and (max-width: 1366px) {
  #footer_container {
    background: url("footer_bg_no_monsters.eb357fec.png") bottom / cover no-repeat;
  }
}

#footer_container .section_separator_light_dark {
  padding: 0;
  position: absolute;
}

#footer_container h3 {
  margin: 25px 0 0;
  font-family: Cinzel;
  font-size: 36px;
}

#footer_container h4 {
  margin: 0;
  padding: 125px 0 0;
  font-size: 36px;
}

#footer_container p {
  margin: 0 0 50px;
  font-size: 28px;
}

#social_icons {
  width: 25%;
  min-width: 400px;
  flex-direction: row;
  margin: 50px auto;
  display: flex;
  overflow: auto;
}

#social_icons a {
  flex: 1;
  display: flex;
}

#social_icons img {
  margin: auto;
}

#footer_menu {
  color: #aaa;
  width: 100%;
  padding-bottom: 25px;
}

#footer_menu a {
  color: #cdcdcd;
  width: 100%;
}

#footer_menu a:hover {
  color: #aaa;
  width: 100%;
}

.footer_logo {
  max-width: 80%;
  margin-top: 100px;
}

@media only screen and (max-width: 800px) {
  #footer_menu {
    font-size: 13px;
  }
}

.cta_frame {
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, .733) url("cta_frame.afce6f20.svg") center no-repeat;
  margin: 0;
  padding: 80px 50px;
  font-family: Cinzel;
}

.cta_frame h2 {
  color: #fff;
  margin: 0 0 20px;
  font-size: 34px;
}

.cta_frame input:focus {
  outline: none;
}

.cta_email_field {
  max-width: 80%;
  color: #fff;
  background: url("cta_email_field.e67ef1cd.svg") 0 0 / contain no-repeat;
  border: none;
  padding: 5px 50px 10px;
  font-family: Sen;
  font-size: 25px;
}

.cta_email_field::-moz-placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_email_field::-ms-input-placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_email_field::placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_email_field::-moz-placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_button {
  color: #000;
  background: url("cta_button.bd483928.svg") 0 / contain no-repeat;
  border: none;
  padding: 5px 60px;
  font-family: Sen;
  font-size: 25px;
}

.cta_button:hover {
  opacity: .9;
}

@media only screen and (max-width: 800px) {
  .cta_frame {
    width: 100%;
    text-align: center;
    background: #285388 url("cta_frame.afce6f20.svg") center no-repeat;
    margin: 10px 0;
    padding: 40px 50px;
    font-family: Cinzel;
  }

  .cta_frame h2 {
    color: #fff;
    margin: 0 0 20px;
    font-size: 20px;
  }

  .mc-field-group {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .cta_email_field {
    width: 80%;
    text-align: center;
    max-width: 400px;
    background: #5a80af;
    border: 2px solid #fff;
    border-radius: 20px;
    margin: 10px 0;
    padding: 3px 10px;
    font-size: 24px;
  }

  .cta_button {
    width: 100%;
    color: #fff;
    max-width: 200px;
    background: #1f8842;
    border-radius: 20px;
    margin: 0;
    padding: 8px 30px;
    font-size: 16px;
    box-shadow: 1px 1px 3px #000;
  }
}

.cta_frame_white {
  width: 100%;
  text-align: center;
  background: url("cta_frame_white.245c71c3.svg") center no-repeat;
  margin: 0 0 100px;
  padding: 60px 50px;
  font-family: Cinzel;
}

.cta_frame_white h2 {
  color: #fff;
  margin: 0 0 20px;
  font-size: 34px;
}

.cta_frame_white input:focus {
  outline: none;
}

.cta_email_field_white {
  max-width: 80%;
  color: #fff;
  background: url("cta_email_field_white.e8b289ef.svg") 0 0 / contain no-repeat;
  border: none;
  padding: 5px 50px 10px;
  font-family: Sen;
  font-size: 25px;
}

.cta_email_field_white::-moz-placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_email_field_white::-ms-input-placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_email_field_white::placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_email_field_white::-moz-placeholder {
  color: rgba(222, 222, 222, .533);
}

.cta_button_white {
  color: #3d3d3d;
  background: url("cta_button_white.3aaa703e.svg") 0 / contain no-repeat;
  border: none;
  padding: 5px 60px;
  font-family: Cinzel;
  font-size: 25px;
}

.cta_button_white:hover {
  opacity: .9;
}

@media only screen and (max-width: 800px) {
  .cta_frame_white {
    margin-bottom: 10px;
  }

  .cta_frame_white h2 {
    color: #fff;
    margin: 0 0 20px;
    font-size: 24px;
  }

  .mc-field-group {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .cta_email_field_white {
    width: 80%;
    text-align: center;
    background-position: center;
    margin: 0;
    padding: 10px 20px;
    font-size: 24px;
  }

  .cta_button_white {
    width: 100%;
    background-position: center;
    margin: 0;
    padding: 8px 30px;
    font-size: 16px;
  }
}

.cta_frame_footer {
  width: 100%;
  text-align: center;
  background-position: center;
  margin: 0;
  padding: 0 0 50px;
  font-family: Cinzel;
  display: none;
}

.cta_frame_footer h2 {
  font-size: 20px;
}

html {
  scroll-behavior: smooth;
}

body {
  background: rgba(18, 9, 32, .95);
  margin: 0;
  padding: 0;
  font: 16px Sen;
}

#container {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

/*# sourceMappingURL=index.27f74f98.css.map */
