
.reveal{
	position: relative;
	transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.reveal_left{
	position: relative;
	transform: translateX(-150px);
    opacity: 0;
    transition: 2s all ease;
}

.reveal_left.active{
  transform: translateX(0);
  opacity: 1;
}

.reveal_right{
	position: relative;
	transform: translateX(150px);
    opacity: 0;
    transition: 2s all ease;
}

.reveal_right.active{
  transform: translateX(0);
  opacity: 1;
}