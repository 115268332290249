#header {
  height: 120vh;
  background: white;
  display: grid;
}

@media only screen and (max-width: 800px) {
  #header {
    height: 77vh;
  }
}

.header_layer{
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 0px;
  background-size: cover;
  
  grid-column: 1;
  grid-row: 1;
}

#header_sky{
  background-image: url('../imgs/header/sky.webp');
}

#header_logo{
  background-image: url('../imgs/header/header_logo.png');
  z-index: 9992;
}

#header_back_mountains{
  background-image: url('../imgs/header/mountain_back.webp');
  z-index: 9993;
}

#header_back_castle{
  background-image: url('../imgs/header/castle_back.webp');
}

#header_monsters_far{
  background-image: url('../imgs/header/monsters_far.webp');
  z-index: 9995;
}

#header_rain_far{
  background-image: url('../imgs/header/rain.gif');
  background-size: 50%;
  background-repeat: repeat;
  opacity: 0.03;
  z-index: 9993;
}

#header_mountains{
  background-image: url('../imgs/header/mountain_middle.webp');
  z-index: 9994;
}

#header_castle{
  background-image: url('../imgs/header/castle_back.webp');
  z-index: 9995;
}

#header_cave{
  background-image: url('../imgs/header/cave.webp');
  z-index: 9996;
}

#header_monsters{
  background-image: url('../imgs/header/monsters.webp');
  z-index: 9996;
}

#header_heroes{
  background-image: url('../imgs/header/heroes.webp');
  z-index: 9997;
}

#header_rain{
  background-image: url('../imgs/header/rain.gif');
  opacity: 0.05;
  z-index: 9998;
}

#header_foreground{
  background-image: url('../imgs/header/foreground.webp');
  z-index: 9999;
}

#header_fog_a{
  background-image: url('../imgs/header/fog_a.png');
  z-index: 9999;
}

#header_fog_b{
  background-image: url('../imgs/header/fog_b.png');
  z-index: 9997;
}

#header_fog_c{
  background-image: url('../imgs/header/fog_c.png');
  z-index: 9994;
}

#header_fog_d{
  background-image: url('../imgs/header/fog_d.png');
  z-index: 9993;
}

@media only screen and (max-width: 800px) {
    
  #header_sky{
    background-image: url('../imgs/header/mobile/sky.jpg');
  }

  #header_logo{
    background-image: url('../imgs/header/mobile/mobile_header_logo.png');
    z-index: 9996;
  }

  #header_back_mountains{
    background-image: url('../imgs/header/mobile/mountain_back.webp');
  }

  #header_back_castle{
    background-image: url('../imgs/header/mobile/castle_back.webp');
  }

  
  #header_monsters_far{
    display: none;
  }

  #header_rain_far{
    background-image: url('../imgs/header/rain.gif');
    background-size: 50%;
    background-repeat: repeat;
    opacity: 0.05;
    z-index: 9993;
  }

  #header_mountains{
    background-image: url('../imgs/header/mobile/mountain_middle.webp');
    z-index: 9994;
  }

  #header_castle{
    background-image: url('../imgs/header/mobile/castle_back.webp');
    z-index: 9995;
  }

    
  #header_cave{
    background-image: url('../imgs/header/mobile/cave.webp');
    z-index: 9996;
  }

  #header_castle_front{
    background-image: url('../imgs/header/mobile/castle_front.webp');
    z-index: 9995;
  }

  #header_monsters{
    background-image: url('../imgs/header/mobile/monsters.webp');
    z-index: 9996;
  }

  #header_heroes{
    background-image: url('../imgs/header/mobile/heroes.webp');
    z-index: 9997;
  }

  #header_rain{
    background-image: url('../imgs/header/rain.gif');
    opacity: 0.05;
    z-index: 9998;
  }

  #header_foreground{
    background-image: url('../imgs/header/mobile/foreground.webp');
    z-index: 9999;
  }

  #header_fog_a{
    background-image: url('../imgs/header/mobile/fog_a.webp');
    z-index: 9999;
  }

  #header_fog_b{
    background-image: url('../imgs/header/mobile/fog_b.webp');
    z-index: 9997;
  }

  #header_fog_c{
    background-image: url('../imgs/header/mobile/fog_c.webp');
    z-index: 9994;
  }

  #header_fog_d{
    background-image: url('../imgs/header/mobile/fog_d.webp');
    z-index: 9993;
  }
}
