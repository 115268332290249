#roadmap{
    /*background: url('../imgs/backgrounds/buildings-min.png');*/
	background-size: auto 120vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-position: 50% top;
}

#spotlight_dark_background{
    background: transparent radial-gradient(closest-side at 50% 50%, rgba(30, 59, 96, 0.9) 0%, rgba(18, 9, 32, 1) 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
}

.roadmap_home_background{
    background: url('../imgs/backgrounds/house.png') no-repeat;
    background-position: bottom right;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


#roadmap_container{
    width: 70%;
}


@media only screen and (max-width: 800px) {
    .roadmap_home_background{
        background: url('../imgs/backgrounds/house_mobile.png') no-repeat;
        background-position: bottom right;
    }

    #roadmap_container{
        width: 85%;
    }
}

#roadmap_container .section_separator{
    padding: 0;
    margin: -60px auto 0;
}

#radmap_fields_holder{
    background: url('../imgs/frames/line.svg') repeat-y;
    background-position: 15%;
    background-size: 25px;

    padding-bottom: 100px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


@media only screen and (max-width: 800px) {
    #radmap_fields_holder{
        background-position: 16%;
    }
}

.roadmap_star{
    background: url('../imgs/frames/roadmap_star.svg') no-repeat;
    background-size: 150px;
    background-position: calc(15% - 45px) center;
    height: 15vh;
    width: 100%;

    color: white;
    margin-bottom: -25px;
}

.roadmap_point_holder{
    width: 100%;
    color: white;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
}

.roadmap_point_holder p{
    font-size: 40px;
    color: white;
    text-align: center;
    width: 100%;
    font-weight: 600;
}

.roadmap_headline{
    font: normal normal 600 60px/52px Cinzel;
    color: white;
    text-align: center;
    font-weight: 600;
    margin-top: 100px;
    margin-bottom: -90px;
}


@media only screen and (max-width: 800px) {
    .roadmap_headline{
        margin-top: 0px;
        margin-bottom: 50px;
    }
}

.roadmap_point_left{
    font-family: Cinzel;
    color: white;
    padding: 25px 0 25px 0px;
    font-size: 30px;
    width: 15%;
    text-align: center;
}

.roadmap_point_right{
    color: white;
    padding: 25px 0 25px 80px;
    font-size: 22px;
    width: 85%;
    text-align: left;

    
    background: url('../imgs/frames/roadmap_line_star.svg') no-repeat;
    background-size: 50px;
    background-position: -4px center;
}

@media only screen and (max-width: 800px) {
    .roadmap_point_right{
        padding-left: 50px;
        font: normal normal 300 calc(16px + 0.3vw)/calc(30px + 0.55vw) Sen;
    }
}

.roadmap_illustration{
    position: relative;
    bottom: 0;
    right: 0;
}