#houses_background{
	background: url('../imgs/backgrounds/buildings.webp');
	background-size: auto 120vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-position: 50% top;
}

#mountains_background{
	background: url('../imgs/backgrounds/mountains.webp');
	background-size: auto 120vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-position: 50% 50%;
}

.spotlight_background{
    background: transparent radial-gradient(closest-side at 50% 50%, rgba(42, 22, 83, 0.9) 0%, rgba(20, 13, 35, 1) 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section_title{
	background: linear-gradient(0deg, rgba(18, 9, 32, 0) 50%, #060307 100%);
	background-size: contain;
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 50px 20px;
}

.section_title_frame_top{
	width: 70%;
	background: url('../imgs/frames/game_title_top.svg') repeat-y;
	background-size: 100% auto;
    background-position: top;
	padding: 0;
}

@media only screen and (max-width: 1000px) {
    .section_title_frame_top{
		width: 90%;
	}
}

#blendint_top{
	background: linear-gradient(0deg, rgba(18, 9, 32, 0) 0%, rgb(9, 4, 11) 100%);
	background-size: contain;
	width: 100%;
	height: 30vh;
	margin-bottom: -30vh;
}

#game_title{
	text-align: center;
	font: normal normal bold calc(26px + 1.6vw)/calc(32px + 1.8vw) Cinzel;
	letter-spacing: 0.8px;
	color: #FFFFFF;
	margin: 0;
	padding: 150px 50px 25px;
}

#game_subtitle{
	color: var(--unnamed-color-ffffff);
	text-align: center;
	font: normal normal 300 calc(16px + 0.3vw)/calc(24px + 0.55vw) Sen;
	letter-spacing: 0.24px;
	color: #FFFFFF;
	margin: 0 50px 30px;
}

@media only screen and (max-width: 800px) {
    #game_title{
		text-align: left;
		padding: 25px 25px 25px;
	}
	
	#game_subtitle{
		text-align: left;
		margin:  0 25px 30px;
		font: normal normal 300 calc(16px + 0.3vw)/calc(30px + 0.55vw) Sen;
	}
}


#stats{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: white;
    width: 60%;
	margin: 75px auto 0;
}

@media only screen and (max-width: 800px) {
    #stats{
		padding-bottom: 0px;
		width: 100%;
    }
}

.stat{
    display: flex;
    flex-direction: column;
    align-items: center;
	margin-bottom: -50px;
}

.stat_big{
	font-size: calc(12px + 1.2vw);
	line-height: calc(12px + 1.2vw);
    font-weight: 800;
}

.stat_small{
	font-size: calc(12px + 0.2vw);
	line-height: calc(16px + 0.4vw);
}


@media only screen and (max-width: 800px) {
	.stat{
		margin-bottom: 0px;
	}

    .stat_big{
        font-size: calc(20px + 1.2vw);
        line-height: calc(24px + 1.2vw);
        font-weight: 800;
    }

    .stat_small{
        font-size: calc(18px + 0.1vw);
        line-height: calc(24px + 0.3vw);
    }
}

#section_title_frame_bottom{
	width: 70%;
}

#section_title_frame_bottom img{
	width: 100%;
}


@media only screen and (max-width: 1000px) {
    #section_title_frame_bottom{
		width: 90%;
	}
}


.description{
	width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 50px 20px;
}

@media only screen and (max-width: 1000px) {
    .description{
		width: 90%;
		padding: 20px 50px 20px;
	}
}

.illustration{
    display: grid;
	grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1000px) {
	.illustration{
		grid-template-columns:auto;
	}
}

.crest_background{
	width: 100%;
	position: fixed;
	max-width: 60%;
	left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 1000px) {
	.crest_background{
		max-width: 40%;
	}
}

.illustration_text{
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column: 1;

	text-align: left;
}

@media only screen and (max-width: 1800px) {
    .illustration_text{
        grid-row-start: 1;
		grid-row-end: 3;
		grid-column: 1;

		display: flex;
		flex-direction: column;
		justify-content: center;
    }
}

.illustration_text.reverse_video_order{
	grid-column: 2;
}

@media only screen and (max-width: 1000px) {
    .illustration_text{
        grid-row-start: 2;
		grid-row-end: 3;
		grid-column: 1;

		padding-bottom: 0px;
    }

	.illustration_text.reverse_video_order{
		grid-column: 1;
		margin-top: 0;
	}
}


.illustration_title{
	font: normal normal bold calc(26px + 1vw)/calc(32px + 1vw) Cinzel;
	letter-spacing: 0.6px;
	color: #FFFFFF;
    margin: 0;
	padding: 0 20px 20px;
}

.illustration_subtitle{
	font: normal normal 300 calc(16px + 0.3vw)/calc(24px + 0.45vw) Sen;
	letter-spacing: 0.24px;
	color: #FFFFFF;
	margin:0;
	padding: 0 0 20px 20px;
}

@media only screen and (max-width: 1000px) {
    .illustration_title{
		padding: 0 25px 20px;
	}
	
	.illustration_subtitle{
		padding: 0 25px 20px;
		font: normal normal 300 calc(16px + 0.3vw)/calc(30px + 0.45vw) Sen;
	}
}

.illustration_image{
	grid-row-start: 1;
	grid-row-end: 3;
	grid-column: 2;
}

.illustration_image.reverse_video_order{
	grid-column: 1;
}

@media only screen and (max-width: 1000px) {
    .illustration_image{
        grid-row-start: 1;
		grid-row-end: 2;
		grid-column: 1;
    }
}

.illustration_video_frame{
	margin: 0 60px;
	background: url('../imgs/frames/video_frame.svg') no-repeat;
	background-size: contain;
	padding: 25px;
}

@media only screen and (max-width: 1800px) {
	.illustration_video_frame{
		max-width: 100%;
		margin: 0 auto 0px;
		padding: 15px;
	}
}

.illustration video{
	width: 100%;
    display: flex;
	margin: 0 auto;
}


@media only screen and (max-width: 1000px) {
	.illustration video{
		max-width: 100%;
		margin: 0 0 30px;
	}
}

.spots{
	grid-row-start: 2;
	grid-row-end: 3;
	grid-column: 1;

	display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0px auto 20px;
}


.spots.reverse_video_order{
	grid-column: 2;
}

@media only screen and (max-width: 1800px) {
	.spots{
		grid-row-start: 3;
		grid-row-end: 3;
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.spots.reverse_video_order{
		grid-row-start: 3;
		grid-row-end: 3;
		grid-column-start: 1;
		grid-column-end: 3;
	}
}


@media only screen and (max-width: 1000px) {
    .spots{
        grid-row-start: 3;
		grid-row-end: 4;
		grid-column: 1;

		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
    }

	.spots.reverse_video_order{
		grid-row-start: 3;
		grid-row-end: 4;
		grid-column: 1;
	}
}

.spot{
	max-width: 33%;
    flex-grow: 1;
	padding: 0px 10px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media only screen and (max-width: 1800px) {
    .spot{
		padding: 20px 30px 0;
		align-items: center;
		flex-shrink: 1;
		width: 45%;
		flex-grow: 0;
    }
}


@media only screen and (max-width: 1000px) {
    .spot{
		padding: 20px 5px 0;
		align-items: center;
		flex-grow: 1;
		width: 45%;
		flex-grow: 0;
    }
}

.spot img{
	width: 100%;
	background: url('../imgs/frames/icons_frame.svg');
	background-size: 110%;
	background-position: 50% 50%;
}


@media only screen and (max-width: 1000px) {
	
	.spot:last-child{
		margin-top: -15%;
	}

	.spot img{
		order: 1;
	}

	.spot:last-child img{
		order: 0;
	}
}

.click_dot{
	display: none;
	border: 4px #ffffff55 solid;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	background: #cdcdcd;

	margin: -30px 0 20px;
}

.spot:hover .click_dot{
	background: #6d6d6d;
}

.spot_label{
	margin: 0;
	padding: 10px 0;
	color: #FFFFFF;
	font: normal normal calc(16px + 0.2vw)/calc(18px + 0.3vw) Sen;
	text-align: center;
}


.scene_headline{
	color: var(--unnamed-color-ffffff);
	text-align: center;
	font: normal normal calc(16px + 0.8vw)/calc(22px + 1vw) Sen;
	letter-spacing: 0.24px;
	color: #FFFFFF;
	margin: 0px 0 50px 0;
}