.section_separator{
    background: url('../imgs/frames/section_separator.png') no-repeat;
    background-position: center center;
    height: 150px;
    width: 100%;
    margin: 100px 0 150px;
}

@media only screen and (max-width: 1000px) {
    .section_separator{
        margin: 20vw 0 20vw;
    }
}

.section_separator_dark_light{
    background: url('../imgs/frames/section_separator_dark_light.svg') no-repeat;
    background-position: center center;
    height: 150px;
    width: 100%;
    margin-top: -73px;
    margin-bottom: -74px;
    position: absolute;
    z-index: 9999;
    left: 0;
}

.section_separator_light_dark{
    background: url('../imgs/frames/section_separator_light_dark.svg') no-repeat;
    background-position: center center;
    height: 150px;
    width: 100%;
    margin-top: -75px;
}

@media only screen and (max-width: 1000px) {
    .section_separator_dark_light{
        background-size: 110%;
    }

    .section_separator_light_dark{
        background-size: 110%;
    }
}