#artstyle{
    background: url('../imgs/backgrounds/buildings.webp');
	background-size: auto 120vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-position: 50% top;
}

#spotlight_blue_background{
    background: transparent radial-gradient(closest-side at 50% 50%, rgba(100, 75, 158, 0.9) 0%, rgba(41, 104, 198, 1) 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}


#artstyle_container{
    width: 70%;
}

@media only screen and (max-width: 800px) {
    #artstyle_container{
        width: 90%;
    }
}

#artstyle_container .section_separator{
    padding: 0;
    margin: -60px auto 0;
}

#artstyle_title_holder{
    padding-bottom: 0px;
}

#artstyle_title_holder_frame_top{
    background: url('../imgs/frames/artstyle_title_top.svg') repeat-y;
    background-size: 100%;
}

#artstyle_title_holder_frame_bottom img{
    width: 100%;
}

#artstyle_title_holder h3{
    color: white;
    text-align: center;
    font: normal normal 600 60px/72px Cinzel;
    letter-spacing: 0.6px;
    margin: 0;
    padding: 180px 50px 50px;

    
	background: linear-gradient(0deg, rgba(18, 9, 32, 0) 0%, #2B68C8 100%);
}

#artstyle_title_holder p{
    color: white;
    text-align: center;
    font: normal normal 300 20px/32px Sen;
    letter-spacing: 0.24px;
    margin: 0;
    padding: 0 50px;
    background-size: 100%;
}

@media only screen and (max-width: 1000px) {
    #artstyle_title_holder{
        width: 100%;
        padding-bottom: 0px;
    }

    #artstyle_title_holder h3{
        padding: 60px 25px 20px;
        font: normal normal 600 40px/52px Cinzel;
        text-align: left;
    }
    
    #artstyle_title_holder p{
        padding: 5px 25px;
        text-align: left;
        font: normal normal 300 calc(16px + 0.3vw)/calc(30px + 0.55vw) Sen;
    }
}

.artstyle_star{
    background: url('../imgs/frames/star.svg') no-repeat;
    background-size: contain;
    background-position: center center;
    height: 20vh;
    width: 100%;
    margin-bottom: -30px;

    position: relative;
    z-index: 9999;
}


@media only screen and (max-width: 1000px) {
    .artstyle_star{
        background: url('../imgs/frames/star_mobile.svg') no-repeat;
        background-size: contain;
        background-position: center center;
        height: 20vh;
        width: 100%;
        margin-bottom: -40px;
        background-size: 160%;
    }
}


#artstyle_fields_holder{
    display: flex;
    flex-direction: row;
    background: url('../imgs/frames/line.svg') repeat-y;
    background-position: center top;
    background-size: 23px;

    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 150px;
    margin-bottom: -10px;
}


@media only screen and (max-width: 800px) {
   
    #artstyle_fields_holder{
        padding-top: 20px;
        padding-bottom: 30px;
    }
}

.artstyle_field{
    width: 43%;
    display: flex;
    flex-direction: column;
    margin: 0 7% 50px 0; 
}

.artstyle_field_right{
    margin: 0 0 0 7%; 
}

.artstyle_field_top{
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.artstyle_field_top img{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.artstyle_field_middle{
    background: url('../imgs/frames/artstyle_field_back.svg') repeat-y;
    background-size: 100% auto;
    padding: 0;
    margin: 0;
    color: white;
    width: 100%;
}

.artstyle_field_middle h3{
    margin: 20px 40px;
    background: url('../imgs/frames/artstyle_field_title_underline.svg') no-repeat;
    background-size: contain;
    background-position: bottom;

    padding-bottom: 20px;
    font: normal normal 600 30px/42px Cinzel;
}

.artstyle_field_middle p, li{
    margin: 20px 50px;
    font: normal normal 600 18px/22px Sen;
    font-weight: 200;
}

.artstyle_field_middle ul{
    padding: 0 10px;
}

.artstyle_field_middle li{
    list-style: none;
    background: url('../imgs/frames/list_star.svg') no-repeat;
    background-position: left top;
    background-size: 22px;
    padding-left: 25px;
}

.artstyle_field_bottom img{
    height: auto;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .artstyle_field{
        width: 100%;
        margin: 25px 0;
    }
}

@media only screen and (max-width: 800px) {
    .artstyle_field_middle p, li{
        font: normal normal 300 calc(16px + 0.3vw)/calc(30px + 0.55vw) Sen;
    }
}

.artstyle_illustration{
    width: 50%;
    justify-content: center;
    flex-direction: column;
    display: flex;
    overflow: hidden;
}

.artstyle_illustration img{
    display: block;
    margin: 0 auto 80px;
    width: 70%;
}


@media only screen and (max-width: 1000px) {
    .artstyle_illustration{
        width: 100%;
        margin: 25px 0;
    }

    .artstyle_illustration img{
        width: 100%;
        margin-bottom: 0;
    }

    .artstyle_illustration.move_to_last{
        order: 4;
    }
}

#artstyling_illustration_left{
    background: url("../imgs/frames/prev_arrow.svg") no-repeat center center;
    width: 20%;
    height: 100%;
    position: absolute;
    left:0;
    opacity: 0.7;
}

#artstyling_illustration_left:hover{
    opacity: 1;
}

#artstyling_illustration_right{
    background: url("../imgs/frames/next_arrow.svg") no-repeat center center;
    width: 20%;
    height: 100%;
    position: absolute;
    right: 0;
    opacity: 0.8;
}

#artstyling_illustration_right:hover{
    opacity: 1;
}

#artstyling_illustration{
    background: url('../imgs/illustration_gifs/golem_artstylings.webp') no-repeat;
    width: 80%;
    margin: 0 10%;
    height: 100%;
    min-height: 500px;
    background-size: calc(100% * 4);
    background-position: 0 center;
    transition: background-position 0.5s ;
}


@media only screen and (max-width: 800px) {   
    #artstyling_illustration{
        min-height: 300px;
    }

    #artstyling_illustration_left{
        background-size: 60%;
    }

    
    #artstyling_illustration_right{
        background-size: 60%;
    }
}

.artstyle_illustration .monster{
    width: 85%;
    height: 100%;
    margin: 0 auto;
}

#artstyle_graph_holder{
    display: none;
    overflow: auto;
    width: 100%;
    margin: 0 0 200px;
}

#artstyle_graph_holder img{
    margin: 3px auto 65px;
    display: block;
    width: 100%;
}