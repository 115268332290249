#top_menu{
	background: #000000aa;
    padding: 0;
	position: fixed;
	width: 100%;
	z-index: 10000;

	display: flex;
	flex-direction: row;
	justify-content: end;
}


.menu_logo, .menu_link{
	padding: 20px 3vw;
	font: normal normal 600 23px/29px Sen;
	letter-spacing: 0.8px;
	color: #FFFFFF;
	margin: 0;
    text-decoration: none;
	text-shadow: 0px 3px 6px #000000AD;
	z-index: 9999;
}

.menu_logo{
	display: block;
	position: absolute;
	z-index: 9999;
	left: 0;
	padding: 12px 20px 15px;
}

.menu_logo img{
	max-width: 200px;
}


@media only screen and (max-width: 1000px) {
	.menu_logo img{
		max-height: 35px;
	}
}


.menu_link:hover{
    color: #CDCDCD;
}


@media only screen and (max-width: 1000px) {
	#top_menu{
		flex-direction: column;
		padding-top: 70px;
	}

	.menu_logo{
		display: block;
		top: 0;
		left: 0;
		padding: 20px 20px 8px;
	}

	.menu_link{
		display: none;
		float: left;
		width: 100%;
		padding: 15px 6vw;
		border-top: 1px #00000055 solid;
		border-bottom: 1px #44444455 solid;
	}

	#top_menu.menu_active .menu_link{
		display: block;
	}

	.menu_link:hover{
		background: #00000033;
	}

	.menu_logo{
		display: block;
		background: #00000055;
		width: 100%;
	}
}
