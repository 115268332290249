/* --- Top Menu styles --- */
@import 'topMenu.css';

/* --- Header styles --- */
@import 'header.css';

/* --- Reveal styles --- */
@import 'section_separator.css';

/* --- Descriptions styles --- */
@import 'descriptions.css';

/* --- Reveal styles --- */
@import 'reveals.css';

/* --- Scenes styles --- */
@import 'scenes.css';

/* --- Artstyle styles --- */
@import 'artstyle.css';

/* --- Roadmap styles --- */
@import 'roadmap.css';

/* --- Team styles --- */
@import 'team.css';

/* --- Footer styles --- */
@import 'footer.css';

/* --- Call to action styles --- */
@import 'calltoaction.css';

html {
    scroll-behavior: smooth;
}

body {
    background: rgba(18, 9, 32, 0.95);
    font: normal normal 16px Sen;
    margin: 0;
    padding: 0;
}

#container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    /*
    width: 80vw;
    margin: 0 auto;
    */

}