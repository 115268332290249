.cta_frame{
    width: 100%;
    text-align: center;
    font-family: Cinzel;
    background: url('../imgs/frames/cta_frame.svg') no-repeat #000000bb;
    background-position: center;
    margin: 0;
    padding: 80px 50px 80px;
}

.cta_frame h2{
    margin: 0 0 20px;
    font-size: 34px;
    color: white;
}

.cta_frame input:focus{
    outline: none;
}

.cta_email_field{
    padding: 5px 50px 10px;
    max-width: 80%;
    font-family: Sen;
    font-size: 25px;
    background: url('../imgs/frames/cta_email_field.svg') no-repeat;
    background-size:contain;
    border: none;
    color: white;
}

.cta_email_field::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #dedede88;
}
  
.cta_email_field::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #dedede88;
}
  
.cta_email_field::-ms-input-placeholder { /* Microsoft Edge */
    color: #dedede88;
}

.cta_button{
    padding: 5px 60px;
    font-family: Sen;
    font-size: 25px;
    background: url('../imgs/frames/cta_button.svg') no-repeat;
    background-size: contain;
    background-position: 0 center;
    border: none;
    color: black;
}

.cta_button:hover{
    opacity: 0.9;
}

@media only screen and (max-width: 800px) {

    .cta_frame{
        width: 100%;
        text-align: center;
        font-family: Cinzel;
        background: url('../imgs/frames/cta_frame.svg') no-repeat #285388;
        background-position: center;
        margin: 10px 0;
        padding: 40px 50px 40px;
    }

    .cta_frame h2{
        margin: 0 0 20px;
        font-size: 20px;
        color: white;
    }

    .mc-field-group{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cta_email_field{
        padding: 3px 10px;
        width: 80%;
        font-size: 24px;
        margin: 10px 0;
        text-align: center;
        background-position: center;
        background: #5a80af;
        border-radius: 20px;
        border: 2px white solid;
        max-width: 400px;
    }

    
    .cta_button{
        padding: 8px 30px;
        font-size: 16px;
        width: 100%;
        margin: 0;
        background-position: center;
        color: white;
        background: #1f8842;
        border-radius: 20px;
        max-width: 200px;
        box-shadow: 1px 1px 3px black;
    }
}

/*white*/

.cta_frame_white{
    width: 100%;
    text-align: center;
    font-family: Cinzel;
    background: url('../imgs/frames/cta_frame_white.svg') no-repeat;
    background-position: center;
    margin: 0 0 100px;
    padding: 60px 50px 60px;

}

.cta_frame_white h2{
    margin: 0 0 20px;
    font-size: 34px;
    color: white;
}

.cta_frame_white input:focus{
    outline: none;
}

.cta_email_field_white{
    padding: 5px 50px 10px;
    max-width: 80%;
    font-family: Sen;
    font-size: 25px;
    background: url('../imgs/frames/cta_email_field_white.svg') no-repeat;
    background-size:contain;
    border: none;
    color: white;
}

.cta_email_field_white::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #dedede88;
}
  
.cta_email_field_white::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #dedede88;
}
  
.cta_email_field_white::-ms-input-placeholder { /* Microsoft Edge */
    color: #dedede88;
}

.cta_button_white{
    padding: 5px 60px;
    font-family: Cinzel;
    font-size: 25px;
    background: url('../imgs/frames/cta_button_white.svg') no-repeat;
    background-size: contain;
    background-position: 0 center;
    border: none;
    color: #3d3d3d;
}

.cta_button_white:hover{
    opacity: 0.9;
}


@media only screen and (max-width: 800px) {

    .cta_frame_white{
        margin-bottom: 10px;
    }

    .cta_frame_white h2{
        margin: 0 0 20px;
        font-size: 24px;
        color: white;
    }

    .mc-field-group{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cta_email_field_white{
        padding: 10px 20px;
        width: 80%;
        font-size: 24px;
        margin: 0;
        text-align: center;
        background-position: center;
    }

    
    .cta_button_white{
        padding: 8px 30px;
        font-size: 16px;
        width: 100%;
        margin: 0;
        background-position: center;
    }

}

.cta_frame_footer{
    width: 100%;
    text-align: center;
    font-family: Cinzel;
    background-position: center;
    margin: 0;
    padding: 0 0 50px;
    display: none;
}

.cta_frame_footer h2{
    font-size: 20px;
}