#footer{
    /*background: url('../imgs/backgrounds/buildings-min.png');*/
	background-size: auto 120vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-position: 50% top;
}


#footer_container{
    width: 100%;
    color: white;
    text-align: center;

    background: url('../imgs/backgrounds/footer_bg.png') no-repeat;
    background-size: cover;
    background-position: bottom center;
}


@media only screen and (max-width: 1366px) {   
    #footer_container{
        background: url('../imgs/backgrounds/footer_bg_no_monsters.png') no-repeat;
        background-size: cover;
        background-position: bottom center;
    }
}


#footer_container .section_separator_light_dark{
    padding: 0;
    position: absolute;
}

#footer_container h3{
    font-size: 36px;
    margin: 25px 0 0;
    font-family: Cinzel;
}

#footer_container h4{
    font-size: 36px;
    padding: 125px 0 0;
    margin: 0;
}

#footer_container p{
    font-size: 28px;
    margin: 0 0 50px;
}

#social_icons{
    overflow: auto;
    margin: 50px auto;
    display: flex;
    flex-direction: row;
    width: 25%;
    min-width: 400px;
}

#social_icons a{
    display: flex;
    flex: 1;
}

#social_icons img{
    margin: auto;
}

#footer_menu{
    color: #aaa;
    width: 100%;
    padding-bottom: 25px;;
}

#footer_menu a{
    color: #cdcdcd;
    width: 100%;
}


#footer_menu a:hover{
    color: #aaa;
    width: 100%;
}

.footer_logo{
    margin-top: 100px;
    max-width: 80%;
}


@media only screen and (max-width: 800px) {
    #footer_menu{
        font-size: 13px;
    }
}
