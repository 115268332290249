/*------------ Defenders Scene Settings ------------*/
#defenders_scene{
  background: url("../imgs/backgrounds/standup_background.webp") no-repeat;
  background-size: 100% auto;
  background-position: 0 70%;
  width: 100%; 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  

  margin-bottom: 100px;
}

.Man1{
  margin-top: 25px;
  background:url('../imgs/sprites/Man1.webp') no-repeat;
}

.Woman1 {
  background:url('../imgs/sprites/Woman1.webp') no-repeat;
}

.Man2{
  background:url('../imgs/sprites/Man2.webp') no-repeat;
}

.Woman2 {
  margin-top: 25px;
  background:url('../imgs/sprites/Woman2.webp') no-repeat;
}

.defender {
  width: 25%;
  height: calc(100vw / 4 / 500 * 500);
  background-size: calc(100%*18) auto;
  background-position: 0 100%;
}


@media only screen and (max-width: 1000px) {
  #defenders_scene{
    background: url("../imgs/backgrounds/standup_background-mobile.png") no-repeat;
    background-position: center bottom;
    background-size: 80%;
    padding-bottom: 0vh;
    margin-bottom: 20px;
    margin-top: -70px;
  }

  .defender {
    width: 50%;
    height: calc(80vw / 2 / 400 * 500);
  }
  
  .Man1, .Woman2{
    margin-top: 0;
    order: 3;
  }
  
  .Woman1, .Man2{
    margin-top: 20px;
    order: 2;
  }

  .Man2{
    margin-top: 35px;
  }
  
}


/*------------ Monsters Scene Settings ------------*/

  
#monsters_scene{
  background: url("../imgs/backgrounds/monsters_background.png") no-repeat;
  background-size: 100% auto;
  background-position: 0 20%;
  width: 100%; 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 150px;
  margin-bottom: 150px;
}

.Ghoul{
  background:url('../imgs/sprites/Ghoul.webp') no-repeat;
  width: calc(100%/1920*370);
  height: calc(90vw / 4 / 470 * 290);
  background-size: calc(100%*16) auto;
}

.Drake {
  background:url('../imgs/sprites/Drake.webp') no-repeat;
  width: calc(100%/1920*300);
  height: calc(90vw / 5 / 470 * 350);
  background-size: calc(100%*16) auto;
}

.Elemental {
  background:url('../imgs/sprites/Elemental.webp') no-repeat;
  width: calc(100%/1920*520);
  height: calc(90vw / 5 / 470 * 520);
  background-size: calc(100%*16) auto;
}

.SmallGhoul {
  background:url('../imgs/sprites/SmallGhoul.webp') no-repeat;
  width: calc(100%/1920*320);
  height: calc(90vw / 4 / 470 * 290);
  background-size: calc(100%*16) auto;
}

.Turtle {
  background:url('../imgs/sprites/Turtle.webp') no-repeat;
  width: calc(100%/1920*400);
  height: calc(90vw / 4 / 470 * 290);
  background-size: calc(100%*16) auto;
}

.monster {
  background-position: 0 100%;
}

@media only screen and (max-width: 800px) {
  #monsters_scene{
    background: url("../imgs/backgrounds/monsters_background-mobile.webp") no-repeat;
    background-size: contain;
    background-position: center center;
    padding: 0 0 0px;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .monster {
    width: 50%;
    height: calc(80vw / 2 / 400 * 500);
  }
  
  .Turtle, .Elemental{
    margin-top: 0;
    order: 1;
  }
  
  .Ghoul, .SmallGhoul{
    order: 2;
  }

  .Drake{
    order: 3;
    margin-top: -90%;
    margin-left: 30%;
    display: none;
  }
  
}