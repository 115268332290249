#team{
    background: url('../imgs/backgrounds/buildings.webp');
	background-size: auto 120vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
	background-position: 50% top;
}


#team_container{
    width: 70%;
}

#team_container .section_separator{
    padding: 0;
    margin: -60px auto 0;
}

#team_fields_holder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    padding-bottom: 150px;
}

#team_fields_holder h3{
    font-family: Cinzel;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    margin: 150px 0 100px;
}

.teammate_holder{
    width: 25%;
    background-size: contain;
    margin-bottom: 50px;
    min-width: 300px;
    font-family: Sen;
}

.teammate_picture{
    padding: 20px 20px 0;
}

.teammate_picture img{
    background-size: 100% 100%;
    margin: 10px auto;
    display: block;
    width: 100%;
}

.teammate_text{
    color: white;
    padding: 0 20px;
}

.teammate_name{
    margin: 5px; font-size: 25px; font-family: Cinzel; text-align: center;
}

.teammate_title{
    margin: 5px; font-size: 16px; font-family: Sen; text-align: center;
}


@media only screen and (max-width: 1000px) {
    #team_container{
        width: 100%;
        padding: 0 25px;
    }

    
    #team_fields_holder{
        padding-bottom: 20px;
    }

    #team_fields_holder h3{
        margin-top: 50px;
        margin-bottom: 0;
    }

    .teammate_text{
        color: white;
        padding: 0 5px;
    }

    
    .teammate_holder{
        min-width: 190px;
        margin-bottom: 0;
    }
}